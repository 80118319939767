import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import useLoader from "../../Components/loader/useLoader";
import { baseurl } from "../../constants";
import Switch from "@mui/material/Switch";
import { deleteOnboarding, deleteOnboardingAdmin, editeOnboarding, editeOnboardingAdmin } from "../Core/Rotes";
var count;
var search = "";
var source = [];

export default function OnBoardings() {
  const [data, setData] = useState();


  const [loader, showLoader, hideLoader] = useLoader();



 



  const getData = async () => {
  
    showLoader();
 
  
    try {
      const response = await fetch(baseurl + "onBoarding/get-onBoardings", {
        method: "GET",
    
      });
      const json = await response.json();
      hideLoader();
      setData(json);
      return json;
    } catch (error) {
      hideLoader();
      console.error(error + "kjjjjjjj");
    }
  };



  const initData = async () => {
    getData();
   
  };

  useEffect(() => {
    if (!data) {
      initData();
    }
  }, []);

  return (
    <div>
      {loader}
      <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
        <div className="breadcrumb-title pe-3">الصور الترحيبية</div>
        <div className="ps-3">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb mb-0 p-0">
              <li className="breadcrumb-item">
                <a href="javascript:;">
                  <i className="bx bx-home-alt" />
                </a>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                قائمة الصور{" "}
              </li>
            </ol>
          </nav>
        </div>
        {/* <div className="ms-auto">
                <Link to={"/admin/field/add"}>

                    <div className="btn-group">
                        <a className="btn btn-success">إضافة +</a>
                    </div>
                </Link>
            </div> */}
      </div>
      {/*end breadcrumb*/}
      <hr />

      <div className="card">
        <div className="card-body">
          <div className="table-responsive">
            <div
              id="example2_wrapper"
              className="dataTables_wrapper dt-bootstrap5"
            >
              <div className="row">
                <div className="col-sm-12">
                  <p></p>
                  
                  <p />

                  <table
                    id="DataTable"
                    className="table table-striped table-bordered dataTable"
                    role="grid"
                    aria-describedby="example2_info"
                  >
                    <thead>
                      <tr role="row">
                        <th
                          className="sorting_asc text-center align-middle"
                          tabIndex={0}
                          aria-controls="example2"
                          rowSpan={1}
                          colSpan={1}
                          aria-sort="ascending"
                          aria-label="Name: activate to sort column descending"
                          style={{ width: "58.828px" }}
                        >
                          <span className="mx-3">م</span>
                        </th>

                        <th
                          className="sorting"
                          tabIndex={0}
                          aria-controls="example2"
                          rowSpan={1}
                          colSpan={1}
                          aria-label="Position: activate to sort column ascending"
                          style={{ width: "120.891px" }}
                        >
                         النص بالعربية{" "}
                        </th>


                        <th
                          className="sorting"
                          tabIndex={0}
                          aria-controls="example2"
                          rowSpan={1}
                          colSpan={1}
                          aria-label="Position: activate to sort column ascending"
                          style={{ width: "120.891px" }}
                        >
                         النص بالانجليزية{" "}
                        </th>
                       

                        <th
                          className="sorting"
                          tabIndex={0}
                          aria-controls="example2"
                          rowSpan={1}
                          colSpan={1}
                          aria-label="Position: activate to sort column ascending"
                          style={{ width: "120.891px" }}
                        >
                         الترتيب{" "}
                        </th>
                        <th
                          className="sorting text-center align-middle"
                          tabIndex={0}
                          aria-controls="example2"
                          rowSpan={1}
                          colSpan={1}
                          aria-label="Position: activate to sort column ascending"
                          style={{ width: "100.891px" }}
                        >
                          صورة{" "}
                        </th>
                        


                        <th
                          className="sorting text-center align-middle"
                          tabIndex={0}
                          aria-controls="example2"
                          rowSpan={1}
                          colSpan={1}
                          aria-label="Office: activate to sort column ascending"
                          style={{ width: "80.312px" }}
                        >
                          تعديل
                        </th>


                      </tr>
                    </thead>

                    <tbody>
                      {data &&
                        data.map((item, index) => (
                          <tr role="row" className="odd">
                            {/* <span className="mx-3">{rowIndex(index)}</span> */}
                            <td className="text-center align-middle">{item.id}</td>
                            <td className="text-center align-middle">{item.descAr}</td>
                            <td className="text-center align-middle">{item.descEng}</td>
                            <td className="text-center align-middle">{item.order}</td>
                         
                            {/* <td>{item.addressName}</td> */}
                            <td className="text-center align-middle" >
                              <div className=" gap-3 cursor-pointer">
                                <img className="rounded-circle"
                                  src={baseurl + "/images/" + item.imageUrl}
                                  // className="rounded-circle"
                                  width={64}
                                  height={64}
                                  alt=""
                                />
                              </div>
                            </td>
                           





                            <td className="text-center align-middle">
                              <Link state={{
                                row: item,
                              }} to={editeOnboardingAdmin}>
                                <a asp-action="Edit" asp-route-id="@item.Id" className="btn btn-success m-1 text-center">
                                  <i className="fas fa-edit" /></a>
                              </Link>

                              <Link state={{
                                row: item,
                              }} to={deleteOnboardingAdmin}>

                                <a asp-action="Delete" asp-route-id="@item.Id" className="btn btn-danger m-1 text-center"><i className="far fa-trash-alt" /></a>


                              </Link>
                            </td>


                          </tr>
                        ))}
                    </tbody>
                  </table>
                  <br />
             
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
