

import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { baseurl, OnInput, onInputInvalid, uploadImage } from "../../constants";




export default function SuggestionDetail() {

  const location = useLocation();
  const row = location.state.row;
 
  return <>




  <div>
    <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
      <div className="breadcrumb-title pe-3">الشكاوي</div>
      <div className="ps-3">
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb mb-0 p-0">
            <li className="breadcrumb-item">
              <a href="javascript:;">
                <i className="bx bx-home-alt" />
              </a>
            </li>
            <li className="breadcrumb-item active" aria-current="page">تفاصيل الشكوي</li>
          </ol>
        </nav>
      </div>
    </div>
    {/*end breadcrumb*/}
    <hr />
    <div className="col-xl-12 mx-auto">
      <div className="card">
        <div className="card-body">
          <div className="border p-3 rounded">
            <h6 className="mb-0 text-uppercase">تفاصيل الشكوي</h6>
            <hr />
              <div asp-validation-summary="ModelOnly" className="text-danger" />
              <div className="row g-3">
            
              
                <div className="col-6">
                  <label className="form-label">الإسم  </label>
                  <input  disabled  defaultValue={row.nameUser}  onInvalid={onInputInvalid }
                        onInput={OnInput} required autoComplete="off" name="Name" type="text" className="form-control" />
                  <span asp-validation-for="Name" className="text-danger" />
                </div>
                <div className="col-6">
                  <label className="form-label">الايميل   </label>
                  <input  disabled defaultValue={row.email} onInvalid={onInputInvalid }
                        onInput={OnInput} required autoComplete="off" name="NameEn" type="text" className="form-control" />
                  <span asp-validation-for="NameEn" className="text-danger" />
                </div>
            
                <div className="col-6">
                  <label className="form-label">رقم التواصل </label>
                  <input  disabled defaultValue={row.phone} autoComplete="off" name="Phone" type="text" className="form-control" />
                </div>

                <div className="col-6">
                  <label className="form-label">وقت الارسال  </label>
                  <input  disabled defaultValue={row.createdAt.split("T")[0]} autoComplete="off" name="Phone" type="text" className="form-control" />
                </div>
               
                <div className="col-6">
                  <label className="form-label">  الرسالة  </label>
                  <textarea  disabled  defaultValue={row.mesaage} onInvalid={onInputInvalid }
                        onInput={OnInput} required autoComplete="off" name="Info" type="text" className="form-control" />
                  <span asp-validation-for="Info" className="text-danger" />
                </div>
              
               
            
             
              </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div>


</div>
  {/* <MarketProducts id={row.id}/> */}

  
  </>

}