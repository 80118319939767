import { useEffect, useState } from "react";
import { toast, Toaster } from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";
import useLoader from "../../Components/loader/useLoader";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import axios from "axios";
import {
  baseurl,
  OnInput,
  onInputInvalid,
  successNotify,
  uploadImage,
} from "../../constants";
import { onboarding } from "../Core/Rotes";

export default function EditeOnBoarding() {


  const [image, setImage] = useState();
  const [order, setOrder] = useState("");
  const [descAr, setDescAr] = useState();
  const [descEng, setDescEng] = useState();
  const [loader, showLoader, hideLoader] = useLoader();

  const location = useLocation();
  const row = location.state.row;
  useEffect(() => {
   
    if (row != null) {
  
      setImage(row.imageUrl);
      
      setOrder(row.order);
      setDescAr(row.descAr);
      setDescEng(row.descEng);
  
  
     
    }
  }, []);


  const handleNewImage = (event) => {
    image = event.target.files[0];
  };

  const handleValidation = () => {
    let errors = [];
    let formIsValid = true;

 

    if (!image) {
      formIsValid = false;

      errors["image"] = "هذا الحقل مطلوب";
    }

   

    return formIsValid;
  };



  const navigateTo = useNavigate();
  const postForm = async () => {
    showLoader();

    if (handleValidation() === false) return;

   

    console.log(image);

    const formdata = new FormData();
    formdata.append("ImageUrl", image);
    formdata.append("Order", order.toString());
    formdata.append("id", row.id.toString());
    formdata.append("descAr", descAr);
    formdata.append("descEng", descEng);

    try {
      const response = await 
      fetch(baseurl + "onBoarding/update-onBoarding", {
        method: "PUT",
        // headers: {
        //   Authorization: "Bearer " + localStorage.getItem("token"),
        // },
        body: formdata,
      });
      const json = await response.json().s;
      hideLoader();
      successNotify();
      console.log(json);
      navigateTo("/admin/"+onboarding);
    } catch (error) {
      console.error(error + "errrrrr");
    }
  };


// ** upload image
const uploadImage = async (file) => {
  try {
    const formData = new FormData();
    formData.append("file", file);

    const resp = await axios
      .post(baseurl + "image/upload/image", formData, {
        headers: {
          "content-type": "multipart/form-data",
        },
      })
      .then((e) => {
       
          setImage(e.data);
  
          console.log(e.data);
      });
  } catch (e) {
    console.log(e);
  }
};



  return (
    <div>
      {loader}
      <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
        <div className="breadcrumb-title pe-3">الصور الترحيبية</div>
        <div className="ps-3">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb mb-0 p-0">
              <li className="breadcrumb-item">
                <a href="javascript:;">
                  <i className="bx bx-home-alt" />
                </a>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                تعديل الصورة
              </li>
            </ol>
          </nav>
        </div>
      </div>
      {/*end breadcrumb*/}
      <hr />
      <div className="col-xl-12 mx-auto text-start">
        <div className="card">
          <div className="card-body">
            <div className="border p-3 rounded">
              <h6 className="mb-0 text-uppercase">معلومات الصورة</h6>
              <hr />
              <div
                asp-validation-summary="ModelOnly"
                className="text-danger "
              />
              <div className="row g-3">

                {/* desc  */}

                <div className="col-6">
                  <label className="form-label">النص باللغة العربية </label>
                  <input
                    onInvalid={onInputInvalid}
                    value={descAr}
                    onChange={(event) => {
                      setDescAr(event.target.value);
                    }}
                    onInput={OnInput}
                    required
                    autoComplete="off"
                    name="Name"
                    type="text"
                    className="form-control"
                  />
                  <span asp-validation-for="Name" className="text-danger" />
                </div>

                <div className="col-6">
                  <label className="form-label">النص باللغة الإنجليزية </label>
                  <input
                    onInvalid={onInputInvalid}
                    value={descEng}
                    onChange={(event) => {
                      setDescEng(event.target.value);
                    }}
                    onInput={OnInput}
                    required
                    autoComplete="off"
                    name="NameEn"
                    type="text"
                    className="form-control"
                  />
                  <span asp-validation-for="Name" className="text-danger" />
                </div>



                {/* ===================== */}
              

               

                <div className="col-6">
                  <label className="form-label">الترتيب </label>
                  <input
                    onInvalid={onInputInvalid}
                    value={order}
                    onChange={(event) => {
                      setOrder(event.target.value);
                    }}
                    onInput={OnInput}
                    required
                    autoComplete="off"
                    name="Order"
                    type="number"
                    className="form-control"
                  />
                  <span asp-validation-for="Name" className="text-danger" />
                </div>
              
                <div class="custom-file">
                  <input
                    type="file"
                    class="custom-file-input"
                    id="customFile"
                    onChange={(value)=>{
                      uploadImage(value.target.files[0]);
                    }}
                  />

                  <div className="col-12">
                    <div className="image col-6">
                      <img
                        src={image!=null?baseurl+"images/"+ image:baseurl+"images/"+ row.imageUrl}
                        width={64}
                        height={64}
                        alt=""
                      />
                    </div>
                  </div>
                </div>

                <div className="col-12" onClick={postForm}>
                  <div className="d-grid">
                    <input
                      type="submit"
                      value="حفظ"
                      className="btn btn-primary"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
